import styled from 'styled-components';
import {
  BaseballScoreBoardBackground,
  BaseballMetalBeamBackground,
  BaseballScoreBoardBraces,
  BaseballSmallBball,
  BaseballOutsRedBall,
} from 'assets/images';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: auto;
  height: 147px;
  z-index: 3;
  margin-bottom: 8px;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  padding: 0.5rem 0 0 1rem;
  position: relative;
  top: -12px;
  height: 152px;
  width: 445px;
  background-image: url(${BaseballScoreBoardBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const Braces = styled.div`
  background-image: url(${BaseballScoreBoardBraces});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 486px;
  height: 153px;
  position: absolute;
  z-index: 0;
  bottom: -8px;
  margin-left: -21px;
`;

export const MetalBeam = styled.div`
  background-image: url(${BaseballMetalBeamBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 464px;
  height: 27px;
  position: absolute;
  z-index: 2;
  bottom: -7px;
  margin-left: -11px;
  z-index: 2;
`;

export const YellowLabel = styled.div`
  text-shadow: 0.1px 2px 5px rgba(0, 0, 0, 0.08);
  font-size: 18.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  width: 35%;

  font-family: 'Barlow Semi Condensed Bold';
  height: 1.938rem;
  display: flex;
  align-items: center;

  &.outs {
    display: flex;
    flex: 1;
    position: relative;
    left: 70px;
    width: 216px;
    height: 1.5rem;
    margin-top: -2px;
  }
  &.outs-pt {
    left: 66px !important;
  }
`;

export const RedValue = styled.div`
  font-family: '5by7';
  font-size: 17.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff001e;
  padding-left: 10px;
  &.scoreboard-value {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const YellowLabelContainer = styled.div`
  display: flex;
`;

export const OutsLevelContainer = styled.div`
  display: flex;
  height: 1.5rem;
`;

export const GreyLabel = styled.div`
  text-shadow: 0.1px 2px 5px rgba(0, 0, 0, 0.08);
  font-family: 'Barlow Semi Condensed Bold';
  font-size: 18.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  width: 158px;
  overflow: hidden;
  text-transform: uppercase;
  position: relative;
`;

export const GreyLabelContents = styled.div`
  white-space: nowrap;
  display: flex;
  position: absolute;
  width: 100%;
  left: -108%;

  ${(prop) =>
    prop.lang === 'pt' || prop.lang === 'pt-BR'
      ? `
      font-size: 17px;
      top: -1px;
      left: -117%;

      @media only screen and (max-device-height: 640px), screen and (max-device-width: 428px) {
        top: 1px;
        font-size: 15px !important;
      }

      `
      : null
  }

  @media only screen and (max-device-height: 640px), screen and (max-device-width: 428px) {
    font-size: 17px;
      left: 0vw;
  }


  text-align: left;

  div {
    /* font-family: 'Varsity Team Bold'; */
  }

  div.first {
    margin-right: 30px;
    @media only screen and (max-height: 640px), screen and (max-width: 430px) {
      margin-right: 28px;
    }
  }

  ${(prop) =>
    prop.animate
      ? `
    color: #face00;
    animation-delay: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-name: marqueeBaseball, hide;
  `
      : null}

  @keyframes hide {
    0%,
    20% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes marqueeBaseball {
    0%,
    20% {
      left: -92%;
    }
    100% {
      left: 100%;
    }
  }
`;

export const HitStreakContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
`;

export const Hit = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  margin-top: 0.125rem;
  background-image: url(${BaseballSmallBball});
  border-radius: 24px;
  background-size: 100% 100%;
  &:nth-child(5) {
    margin-left: -1px;
  }
  &:nth-child(8) {
    margin-left: -1px;
  }
  &:nth-child(12) {
    margin-left: -1px;
  }
`;

export const OutsContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 28px;
`;

export const OutBall = styled.div`
  width: 27px;
  height: 27px;
  background-image: url(${BaseballOutsRedBall});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:nth-child(2) {
    margin-left: 4px;
  }

  &:nth-child(3) {
    margin-left: 5px;
  }

  ${(prop) =>
    prop.shouldRotate
      ? `
    animation: rotate 0.6s infinite linear;
  `
      : null};

  @keyframes rotate {
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

export const PrizeMoneyContainer = styled.div`
  font-family: 'Varsity Team Bold';
  font-size: 18.5px;
  font-weight: bold;
  color: #face00;
  text-align: center;
  position: fixed;
  right: 0;
  left: 0;
  margin-left: -170px;
  animation: moveSize 1s linear 0.5s forwards, blink 0.5s infinite linear;
  z-index: 4;

  @keyframes moveSize {
    100% {
      margin-left: 0;
      margin-top: 230px;
      font-size: 80pt;
    }
  }

  @keyframes blink {
    0% {
      color: #face00;
    }
    100% {
      color: #ffffff;
    }
  }
`;

export const Blanket = styled.div`
  position: fixed;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7);
  top: -20px;
  left: -5000%;
  bottom: 0;
  right: -5000%;
`;
